
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    imgList: {
      type: Array as PropType<string[]>,
      require: true,
    },
  },
});
